<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
          <div class="patrons">
            <router-link
              to="/patroni/matka-boza"
              class="patrons__item"
              @mouseenter.native="active = 3"
              @mouseleave.native="active = null"
              :class="[
                active == 3 ? 'patrons__item--active' : '',
                active != 3 && active != null ? 'patrons__item--disable' : '',
              ]"
            >
              <img src="@/assets/patrons/MB_z_napisami.jpg" alt="" />
            </router-link>
            <router-link
              to="/patroni/filip-neri"
              class="patrons__item"
              @mouseenter.native="active = 4"
              @mouseleave.native="active = null"
              :class="[
                active == 4 ? 'patrons__item--active' : '',
                active != 4 && active != null ? 'patrons__item--disable' : '',
              ]"
            >
              <img src="@/assets/patrons/Neri_z_napisem.jpg" alt="" />
            </router-link>
            <router-link
              to="/patroni/jan-henryk-newman"   
              class="patrons__item"
              @mouseenter.native="active = 5"
              @mouseleave.native="active = null"
              :class="[
                active == 5 ? 'patrons__item--active' : '',
                active != 5 && active != null ? 'patrons__item--disable' : '',
              ]"
            >
              <img src="@/assets/patrons/Newman_z_napisami.jpg" alt="" />
            </router-link>
            <router-link
              to="/patroni/jose-sanchez-del-rio"
              class="patrons__item"
              @mouseenter.native="active = 2"
              @mouseleave.native="active = null"
              :class="[
                active == 2 ? 'patrons__item--active' : '',
                active != 2 && active != null ? 'patrons__item--disable' : '',
              ]"
            >
              <img src="@/assets/patrons/Jose_z_napisem.jpg" alt="" />
            </router-link>
            <router-link
              to="/patroni/chiara-luce-badano"
              class="patrons__item"
              @mouseenter.native="active = 1"
              @mouseleave.native="active = null"
              :class="[
                active == 1 ? 'patrons__item--active' : '',
                active != 1 && active != null ? 'patrons__item--disable' : '',
              ]"
            >
              <img src="@/assets/patrons/Chiara_z_napisami.jpg" alt="" />
            </router-link>
          </div>
          <div class="patrons__text">
            <p>Kliknij na zdjęcie by zobaczyć opis</p>
          </div>
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      active: null,
      selected: null,
    };
  },
};
</script>
<style lang="scss" scoped>
.patrons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  &__item {
    width: 100%;
    margin-right: 0;
    text-align: center;
    transition: all 1s;
    opacity: 1;
    position: relative;
    @media (min-width: 900px) {
      margin-right: 2rem;
      width: calc(100% / 3 - 2rem);
    }
    &--disable {
      opacity: 0.3;
    }
    &--active {
      cursor: pointer;
      opacity: 1;
      .patrons__subtitle {
        right: -40px;
        opacity: 1;
      }
    }
  }
  &__text {
    text-align: right;
    text-transform: uppercase;
    margin-top: 3rem;
    animation-delay: 2s;
    animation-duration: 2s;
    animation-name: example;
    opacity: 0;
    animation-fill-mode: forwards;
    @media (max-width: 900px) {
      p {
        font-size: 0.8rem;
      }
    }
  }
}
@keyframes example {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 1s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 1;
// }
</style>