var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide"}},[_c('div',{staticClass:"subpage"},[_c('PageText',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$route.meta.title))]},proxy:true},(_vm.$route.meta.subtitle)?{key:"subtitle",fn:function(){return [_vm._v(_vm._s(_vm.$route.meta.subtitle))]},proxy:true}:null,{key:"text",fn:function(){return [_c('div',{staticClass:"patrons"},[_c('router-link',{staticClass:"patrons__item",class:[
              _vm.active == 3 ? 'patrons__item--active' : '',
              _vm.active != 3 && _vm.active != null ? 'patrons__item--disable' : '' ],attrs:{"to":"/patroni/matka-boza"},nativeOn:{"mouseenter":function($event){_vm.active = 3},"mouseleave":function($event){_vm.active = null}}},[_c('img',{attrs:{"src":require("@/assets/patrons/MB_z_napisami.jpg"),"alt":""}})]),_c('router-link',{staticClass:"patrons__item",class:[
              _vm.active == 4 ? 'patrons__item--active' : '',
              _vm.active != 4 && _vm.active != null ? 'patrons__item--disable' : '' ],attrs:{"to":"/patroni/filip-neri"},nativeOn:{"mouseenter":function($event){_vm.active = 4},"mouseleave":function($event){_vm.active = null}}},[_c('img',{attrs:{"src":require("@/assets/patrons/Neri_z_napisem.jpg"),"alt":""}})]),_c('router-link',{staticClass:"patrons__item",class:[
              _vm.active == 5 ? 'patrons__item--active' : '',
              _vm.active != 5 && _vm.active != null ? 'patrons__item--disable' : '' ],attrs:{"to":"/patroni/jan-henryk-newman"},nativeOn:{"mouseenter":function($event){_vm.active = 5},"mouseleave":function($event){_vm.active = null}}},[_c('img',{attrs:{"src":require("@/assets/patrons/Newman_z_napisami.jpg"),"alt":""}})]),_c('router-link',{staticClass:"patrons__item",class:[
              _vm.active == 2 ? 'patrons__item--active' : '',
              _vm.active != 2 && _vm.active != null ? 'patrons__item--disable' : '' ],attrs:{"to":"/patroni/jose-sanchez-del-rio"},nativeOn:{"mouseenter":function($event){_vm.active = 2},"mouseleave":function($event){_vm.active = null}}},[_c('img',{attrs:{"src":require("@/assets/patrons/Jose_z_napisem.jpg"),"alt":""}})]),_c('router-link',{staticClass:"patrons__item",class:[
              _vm.active == 1 ? 'patrons__item--active' : '',
              _vm.active != 1 && _vm.active != null ? 'patrons__item--disable' : '' ],attrs:{"to":"/patroni/chiara-luce-badano"},nativeOn:{"mouseenter":function($event){_vm.active = 1},"mouseleave":function($event){_vm.active = null}}},[_c('img',{attrs:{"src":require("@/assets/patrons/Chiara_z_napisami.jpg"),"alt":""}})])],1),_c('div',{staticClass:"patrons__text"},[_c('p',[_vm._v("Kliknij na zdjęcie by zobaczyć opis")])])]},proxy:true}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }